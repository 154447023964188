@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* cell style */
.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    line-height: 20px !important;
    padding-right: 0 !important;
}

.ag-cell-wrap-text {
    word-break: break-word !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.title {
  background-color: transparent;
  font-size: large;
  color: black;
}

/* cell style */
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 20px !important;
  padding-right: 0 !important;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}
.pagination_wrapper {
  font-size: 16px !important;
  position: absolute;
  right: 10px;
}

.rc-pagination-item {
  border: none !important;
  color: rgba(0, 0, 0, 0.32) !important;
}
.rc-pagination-item a :hover {
  color: black;
}
.rc-pagination-item-active a {
  color: black !important;
  font-weight: bold !important;
}

.rc-pagination-item-link {
  border: none !important;
}
li:hover {
  color: black !important;
}
.rc-pagination-prev button,
.rc-pagination-next button {
  font-size: 30px !important;
  bottom: 7px !important;
  position: relative;
}
.rc-pagination-item:focus a,
.rc-pagination-item:hover a {
  color: black !important;
}

.rc-pagination-item-link :hover {
  color: black !important ;
}

.rc-pagination-next button :hover {
  color: black !important;
}

@font-face {
  font-family: Helvetica;
  panose-1: 2 11 6 4 2 2 2 2 2 4;
}
@font-face {
  font-family: Wingdings;
  panose-1: 5 0 0 0 0 0 0 0 0 0;
}
@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin: 0in;
  font-size: 11pt;
  font-family: "Calibari", sans-serif;
}
.MsoChpDefault {
  font-family: "Calibri", sans-serif;
}
@page WordSection1 {
  size: 8.5in 11in;
  margin: 1in 1in 1in 1in;
}
div.WordSection1 {
  padding: 8px;
}
/* List Definitions */
ol {
  margin-bottom: 0in;
}
ul {
  margin-bottom: 0in;
}

