@font-face {
  font-family: Helvetica;
  panose-1: 2 11 6 4 2 2 2 2 2 4;
}
@font-face {
  font-family: Wingdings;
  panose-1: 5 0 0 0 0 0 0 0 0 0;
}
@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin: 0in;
  font-size: 11pt;
  font-family: "Calibari", sans-serif;
}
.MsoChpDefault {
  font-family: "Calibri", sans-serif;
}
@page WordSection1 {
  size: 8.5in 11in;
  margin: 1in 1in 1in 1in;
}
div.WordSection1 {
  padding: 8px;
}
/* List Definitions */
ol {
  margin-bottom: 0in;
}
ul {
  margin-bottom: 0in;
}
