/* cell style */
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 20px !important;
  padding-right: 0 !important;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}
.pagination_wrapper {
  font-size: 16px !important;
  position: absolute;
  right: 10px;
}

.rc-pagination-item {
  border: none !important;
  color: rgba(0, 0, 0, 0.32) !important;
}
.rc-pagination-item a :hover {
  color: black;
}
.rc-pagination-item-active a {
  color: black !important;
  font-weight: bold !important;
}

.rc-pagination-item-link {
  border: none !important;
}
li:hover {
  color: black !important;
}
.rc-pagination-prev button,
.rc-pagination-next button {
  font-size: 30px !important;
  bottom: 7px !important;
  position: relative;
}
.rc-pagination-item:focus a,
.rc-pagination-item:hover a {
  color: black !important;
}

.rc-pagination-item-link :hover {
  color: black !important ;
}

.rc-pagination-next button :hover {
  color: black !important;
}
